@tailwind base;
@tailwind modules;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,500,600,700&display=auto');
@font-face {
  font-family: 'Lola';
  font-display: auto;
  src: url('/fonts/fslola-medium-webfont.woff') format('woff'),
    url('/fonts/fslola-medium-webfont.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Lola light';
  font-display: auto;
  src: url('/fonts/FS Lola Light Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'SourceSansPro Bold';
  font-display: auto;
  src: url('/fonts/SourceSansPro-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'SourceSansPro Light';
  font-display: auto;
  src: url('/fonts/SourceSansPro-Light.ttf') format('truetype');
  font-weight: lighter;
  font-style: normal;
}
@font-face {
  font-family: 'SourceSansPro SemiBold';
  font-display: auto;
  src: url('/fonts/SourceSansPro-SemiBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'SourceSansPro Regular';
  font-display: auto;
  src: url('/fonts/SourceSansPro-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'SourceSansPro LightItalic';
  font-display: auto;
  src: url('/fonts/SourceSansPro-LightItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'LolaRegular';
  font-display: auto;
  src: url('/fonts/FSLola-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

html,
body {
  @apply text-small lg:text-base font-waldo-sans font-light;
  scroll-padding-top: 6.5rem;
  scroll-behavior: smooth;
  font-weight: normal;
}
body.sidebar-open,
body.modal-open {
  @apply overflow-hidden;
  scroll-behavior: none;
}
article {
  @apply font-light;
}
summary {
  @apply list-none;
}
div {
  @apply box-border;
}
p,
label {
  @apply text-waldo-blue leading-tight text-base;
}
a {
  @apply text-waldo-blue hover:text-waldo-tangerine cursor-pointer;
}
label {
  @apply font-light;
}
h1 {
  @apply text-waldo-blue font-waldo-header text-3xl lg:text-4xl leading-8 lg:leading-10;
}
h2 {
  @apply text-waldo-blue font-waldo-header text-large leading-9;
}
h3 {
  @apply text-waldo-blue font-waldo-header text-3xl leading-8;
}
h4 {
  @apply font-waldo-header text-3xl leading-8;
}

/* form , control buttons */
input[type='input'],
input[type='text'],
input[type='password'],
input[type='email'],
input[type='number'],
select,
textarea {
  @apply w-full bg-white py-1 my-6 pr-7 outline-none box-border
        border-b border-solid border-waldo-blue border-opacity-25
        placeholder-opacity-50 text-waldo-blue font-normal placeholder-waldo-blue font-waldo-sans  leading-4 text-base outline-none
        focus:outline-none;
}

input[type='input'].input-funnel,
input[type='text'].input-funnel,
input[type='password'].input-funnel,
input[type='email'].input-funnel,
input[type='number'].input-funnel,
select.input-funnel,
textarea.input-funnel {
  @apply text-small w-full leading-4 px-0 py-3 my-8 border-0 border-b border-solid border-border-blue text-waldo-blue font-light outline-none;
}

input[type='password'].input-account,
input[type='input'].input-account,
input[type='text'].input-account {
  @apply text-small w-full leading-4 px-3 py-4 mt-2 mb-6 border border border-solid border-waldo-blue rounded-md text-waldo-blue font-normal outline-none;
}

input[type='text'].sign-up {
  @apply my-0;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

textarea:focus,
input:focus {
  @apply outline-none;
}

select.input-account-select {
  @apply text-small w-full leading-4 px-3 pt-3 pb-4 mt-2 mb-6 border border border-solid border-waldo-blue rounded-md text-waldo-blue font-normal outline-none;
}

input.input-promo {
  @apply border-l border-t border-b my-0 p-4 rounded-l-lg border-waldo-blue;
}

input.input-refer {
  @apply border my-0 p-4 rounded placeholder-waldo-blue md:w-60;
}

input[type='input'].input-doctor,
input[type='text'].input-doctor,
select.input-doctor,
select.input-doctor,
fieldset .input-doctor,
textarea.input-doctor {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  @apply text-small w-full leading-4 px-3 py-4 my-4  border border-solid border-waldo-blue bg-pale-blue rounded-md text-waldo-blue font-semibold outline-none;
}

input[type='password'].input-login,
input[type='email'].input-login {
  @apply placeholder-white placeholder-opacity-50;
}

input[type='input'].input-error-border,
input[type='text'].input-error-border,
input[type='password'].input-error-border,
input[type='email'].input-error-border,
select.input-error-border {
  @apply border-waldo-tangerine border-b border-opacity-100;
}

input[type='input'].input-error-margin,
input[type='text'].input-error-margin,
input[type='password'].input-error-margin,
input[type='email'].input-error-margin,
select.input-error-margin {
  @apply mb-0;
}

.waldo-login-error {
  margin-bottom: 0.25rem;
}

input[type='text'].input-discount-code {
  @apply border border-solid border-waldo-blue py-3.5 mt-0 px-2;
}
input[type='text'].input-blue,
input[type='email'].input-blue {
  @apply border border-solid border-white bg-waldo-blue text-white;
}
textarea.input-orange {
  @apply border border-solid border-waldo-tangerine;
}

label > input[type='checkbox'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  @apply bg-waldo-blue border-white border-b border-t border-l border-r mr-4 cursor-pointer outline-none block mt-0.5;
}

label > input[type='checkbox']:checked {
  @apply border-white border bg-waldo-blue;
}

label > input[type='checkbox']:checked + span::before {
  content: '\2713';
  top: 0.1rem;
  left: 0.2rem;
  @apply text-white text-center block absolute;
}

.button {
  @apply font-waldo-header inline uppercase cursor-pointer no-underline py-2 px-5 overflow-hidden whitespace-nowrap transition-transform font-semibold text-base transition duration-300 hover:-translate-y-1;
}

.button-second {
  @apply font-waldo-header uppercase cursor-pointer no-underline py-4 transition-transform font-semibold text-base px-14 md:py-4 transition duration-300 hover:-translate-y-1;
}

.button-hero-home {
  @apply font-waldo-header inline uppercase cursor-pointer no-underline py-6 px-5 overflow-hidden whitespace-nowrap transition-transform font-semibold text-base md:px-10 md:py-4 transition duration-300 hover:-translate-y-1;
}

.button-carousel {
  @apply font-waldo-header uppercase cursor-pointer no-underline transition-transform py-2 font-semibold text-base transition duration-300 hover:-translate-y-1;
}

.btn-cta {
  @apply text-white bg-waldo-salmon-pink border-waldo-salmon-pink border border-solid hover:bg-white hover:text-waldo-salmon-pink hover:border-waldo-salmon-pink text-center disabled:opacity-50 disabled:cursor-default disabled:bg-waldo-salmon-pink disabled:text-white;
}

.btn-cta-uk {
  @apply text-white bg-waldo-tangerine border-waldo-tangerine border border-solid hover:bg-white hover:text-waldo-tangerine hover:border-waldo-tangerine text-center disabled:opacity-50 disabled:cursor-default disabled:bg-waldo-salmon-pink disabled:text-white;
}

.btn-secondary-cta {
  @apply text-white bg-waldo-blue border-white border border-solid  hover:border-waldo-salmon-pink text-center disabled:opacity-50 disabled:cursor-default;
}

.btn-secondary-cta-second {
  @apply text-white bg-waldo-blue hover:border hover:border-white text-center disabled:opacity-50 disabled:cursor-default;
}

.btn-third-cta {
  @apply text-waldo-blue bg-none border-waldo-blue border border-solid  hover:bg-waldo-blue hover:text-white text-center disabled:opacity-50 disabled:cursor-default;
}

.btn-faq-cta {
  @apply text-waldo-blue bg-white border-waldo-blue border border-solid hover:border-waldo-tangerine hover:text-waldo-tangerine text-center;
}
.btn-simple-blue {
  @apply uppercase text-waldo-blue border border-waldo-blue cursor-pointer no-underline py-6 w-11/12 transition-transform font-semibold hover:bg-waldo-blue hover:text-white text-base px-14 md:py-4 transition duration-300 hover:-translate-y-1;
}

.btn-simple-blue-small {
  @apply uppercase text-waldo-blue border border-waldo-blue cursor-pointer no-underline py-4 w-11/12 transition-transform font-semibold hover:bg-waldo-blue hover:text-white text-extraSmall transition duration-300 hover:-translate-y-1;
}

.btn-learn-more-cta {
  @apply text-waldo-tangerine border-waldo-tangerine border border-solid  hover:border-waldo-tangerine hover:text-white text-center;
}

.btn-account-box {
  @apply hidden w-20 p-[0.6rem] border-waldo-blue bg-transparent text-waldo-blue font-semibold text-base lg:absolute lg:-top-3 lg:-right-1 lg:group-hover:block hover:text-waldo-tangerine hover:bg-transparent;
}

.btn-cta-hbp {
  @apply text-white bg-waldo-salmon-pink border-waldo-salmon-pink border border-solid hover:bg-white hover:text-waldo-salmon-pink hover:border-waldo-salmon-pink text-center disabled:opacity-50 disabled:cursor-default disabled:bg-waldo-salmon-pink disabled:text-white;
}

.checkbox input[type='radio']:checked + span {
  @apply border-waldo-tangerine border text-waldo-tangerine;
}

.swatch {
  @apply h-6 w-6 mr-3 inline-block align-middle bg-no-repeat bg-center border-solid border-gray-500 rounded-1/2;
}

label > input[type='checkbox'].check-form {
  width: 19px;
  height: 19px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  @apply bg-white border-waldo-blue border mr-4 cursor-pointer outline-none block mt-0.5;
}

label > input[type='checkbox'].check-form {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  @apply bg-white border-waldo-blue border-b border-t border-l border-r mr-4 cursor-pointer outline-none block mt-0.5;
}

label > input[type='checkbox']:checked.check-form {
  @apply border-waldo-blue border bg-white;
}

input[type='checkbox']:checked.check-form + span::before {
  content: '\2713';
  top: 0rem;
  left: 0.2rem;
  @apply text-waldo-blue text-center block absolute;
}
.toggle-checkbox:checked {
  @apply right-0 border-waldo-blue;
}
.toggle-checkbox:checked + .toggle-label {
  @apply bg-waldo-blue;
}

#payment-form .StripeElement {
  @apply mt-5 w-full bg-white py-1 my-6 pr-7 outline-none box-border
  border-b border-solid border-waldo-blue border-opacity-25
  placeholder-opacity-50 text-waldo-blue font-normal placeholder-waldo-blue font-waldo-sans  leading-4 text-base outline-none
  focus:outline-none;
}
.blue-overlay {
  @apply bg-funnel-blue opacity-40;
}

#mmWrapperDESK #mmLink,
#mmWrapperMOB #mmLink {
  color: #fe5000;
  font-weight: normal;
}

@layer base {
  ul,
  ol {
    list-style: revert;
  }
}
.slick-prev:before {
  left: 25px;
  content: unset;
  color: transparent;
  display: none;
}

.slick-next {
  right: 25px;
}

.slick-next:before {
  right: 25px;
  top: 50px;
  content: unset;
  color: transparent;
  display: none;
}
.slick-disabled {
  opacity: 0.5 !important;
  cursor: default !important;
}
select {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
}

.bg-white-sands {
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 56%,
    rgba(234, 230, 221, 1) 56%
  );
}

#waldo-header {
  position: fixed;
  width: 100%;
  background-color: rgb(255, 255, 255);
  z-index: 100;
}

#waldo-header .header-mobile .header-navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: flex-start;
  position: sticky;
  top: 0px;
  width: 100%;
  padding: 0.75rem 0.85rem;
  background-color: rgb(255, 255, 255);
  z-index: 1;
}

#waldo-header .header-mobile .header-navbar .hamburger {
  display: flex;
  justify-content: flex-start;
  width: 33%;
  color: #0032a0;
  font-size: 2.25rem;
}

#waldo-header .header-desktop {
  width: 100%;
}

#waldo-header .header-desktop .header-navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  z-index: 1;
  padding: 0 1rem;
}

#waldo-header.headerBanner .header-desktop .header-navbar {
  padding: 0.75rem 1rem;
}

#waldo-header .header-desktop .left-side-links {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 40%;
  height: 3rem;
  font-size: 1.15rem;
  color: #0032a0;
}

#waldo-header .header-desktop .left-side-links > div {
  padding: 0 1.5rem 0 0;
  text-transform: uppercase;
}

.mobile-account-payment-details-alert {
  @apply font-waldo-sans-medium;
}

.desktop-account-payment-details-alert {
  @apply font-waldo-sans-medium;
}

@media (max-width: 767px) {
  #waldo-header .header-desktop {
    display: none;
  }

  .home-hero-padding-top {
    padding-top: 4rem;
  }

  .home-hero-padding-top-banner {
    padding-top: 10rem;
  }

  .standard-padding-top {
    padding-top: 4rem;
  }

  .standard-padding-top-banner {
    padding-top: 10rem;
  }

  .alternate-padding-top {
    padding-top: 3rem;
  }

  .alternate-padding-top-banner {
    padding-top: 7rem;
  }

  .small-padding-top {
    padding-top: 2.5rem;
  }

  .small-padding-top-banner {
    padding-top: 6.5rem;
  }

  .other-padding-top {
    padding-top: 1rem;
  }

  .pdp-padding-top {
    padding-top: 4rem !important;
  }

  .pdp-padding-top-banner {
    padding-top: 9.5rem;
  }

  .funnel-padding-top {
    padding-top: 4rem;
  }

  .funnel-padding-top-banner {
    padding-top: 8rem;
  }

  .single-product-padding-top {
    padding-top: 4rem !important;
  }

  .single-product-padding-top-banner {
    padding-top: 9.9rem !important;
  }

  .standard-account-welcome {
    top: 5.5rem;
    height: 14.5rem;
  }

  .standard-account-welcome-banner {
    top: 10.5rem;
  }

  .standard-account-container {
    margin-top: 8.75rem;
  }

  .standard-account-container-banner {
    margin-top: 12.75rem;
  }

  .mobile-account-payment-details-alert {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 24rem;
    margin: 1rem auto -0.5rem auto;
    padding: 0.5rem 1rem;
    background-color: #d0eee8;
    color: #0057b7;
    border-radius: 0.25rem;
  }

  .mobile-account-payment-details-alert svg {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.5rem;
  }

  .mobile-account-payment-details-alert span {
    padding-top: 0.05rem;
  }

  .desktop-account-payment-details-alert {
    display: none;
  }

  .no-sales-message p {
    @apply text-waldo-blue;
    padding: 0rem 2rem;
  }

  .no-sales-message p.no-sales-message-account {
    @apply font-waldo-sans-bold;
    margin-top: 2rem;
  }

  .subscription-box-contents {
    width: 11rem;
  }
}

@media (min-width: 768px) {
  #waldo-header {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  #waldo-header.headerPlain.headerBanner {
    flex-direction: column;
  }

  #waldo-header .header-mobile {
    display: none;
  }

  .home-hero-padding-top {
    padding-top: 7rem;
  }

  .home-hero-padding-top-banner {
    padding-top: 9.2rem;
  }

  .standard-padding-top {
    padding-top: 4.5rem;
  }

  .standard-padding-top-banner {
    padding-top: 6.7rem;
  }

  .blg-padding-top-banner {
    padding-top: 7.7rem;
  }

  .alternate-padding-top {
    padding-top: 3rem;
  }

  .alternate-padding-top-banner {
    padding-top: 5.2rem;
  }

  .small-padding-top {
    padding-top: 2rem;
  }

  .small-padding-top-banner {
    padding-top: 4.5rem;
  }

  .pdp-padding-top {
    padding-top: 4rem;
  }

  .pdp-padding-top-banner {
    padding-top: 6.2rem;
  }

  .funnel-padding-top {
    padding-top: 4rem !important;
  }

  .funnel-padding-top-banner {
    padding-top: 6.2rem !important;
  }

  .single-product-padding-top {
    padding-top: 5.7rem !important;
  }

  .single-product-padding-top-banner {
    padding-top: 7.9rem !important;
  }

  .standard-account-welcome {
    top: 7rem;
  }

  .standard-account-welcome-banner {
    top: 9.2rem;
  }

  .standard-account-container {
    margin-top: 12rem;
  }

  .standard-account-container-banner {
    margin-top: 14.2rem;
  }

  .desktop-account-payment-details-alert {
    display: flex;
    flex-direction: row;
    width: 1014px;
    margin: 1rem auto -0.5rem auto;
    padding: 0.5rem 1rem;
    background-color: #d0eee8;
    color: #0057b7;
    border-radius: 0.25rem;
  }

  .desktop-account-payment-details-alert svg {
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 0.5rem;
    margin-top: 0.2rem;
  }

  .desktop-account-payment-details-alert span {
    padding-top: 0.05rem;
  }

  .mobile-account-payment-details-alert {
    display: none;
  }

  .no-sales-message p {
    @apply text-waldo-blue;
    padding: 0rem 20rem;
  }

  .no-sales-message p.no-sales-message-account {
    @apply font-waldo-sans-bold;
    margin-top: 2rem;
  }

  .subscription-box-contents {
    width: 14rem;
  }
}

.mobile-sightsavers-products {
  @apply hidden;
}

.desktop-sightsavers-products {
  margin-left: 12rem;
  margin-right: 12rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.funnel-text-title {
  font-size: 1.1rem;
}

.esk-slick-padding {
  padding: 5rem 2rem;
}

.esk-funnel-image {
  width: 92%;
}

.esk-shopall-image {
  width: 12rem;
}

.esk-slider-thumb {
  height: 3.5rem;
  padding: 0.85rem 0.25rem;
}

img.esk-slider-image {
  padding: 9.3rem 1.5rem;
}

@media (max-width: 1300px) {
  .mobile-sightsavers-products {
    @apply block;
  }

  .desktop-sightsavers-products {
    @apply hidden;
  }

  .esk-funnel-image {
    width: 100%;
  }

  img.esk-slider-image {
    padding: 6.5rem 0.5rem;
  }
}

.header-desktop .right-side-links {
  justify-content: flex-end;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 40%;
  height: 3rem;
  font-size: 1.15rem;
  color: #0032a0;
}

.header-desktop .right-side-links > div {
  padding: 0 0 0 1.5rem;
  text-transform: uppercase;
}

.header-desktop .right-side-links .buttonInputCTA button {
  width: 140px;
  height: 2.5rem;
}

.header-desktop .right-side-links .account-link .region-links {
  width: 3.5rem;
}

.header-desktop
  .right-side-links
  .account-link
  .region-links
  .dropdown-content {
  left: 12%;
}

.account-payment-method-icon-visa {
  margin-top: 0.2rem;
}

.account-payment-method-icon-mastercard {
  margin-top: 0.1rem;
}

.account-payment-method-icon-amex {
  margin-top: 0.55rem;
  opacity: 0.3;
  height: 0.95rem;
}

.account-payment-method-icon-stripe {
  height: 2rem;
}

.account-payment-method-icon-google {
  margin-top: 0.3rem;
}

.account-payment-method-icon-apple {
  margin-top: 0.2rem;
}

.account-payment-method-icon-paypal {
  margin-top: 0.1rem;
}

.status-label-active,
.status-label-paused,
.status-label-cancelled {
  margin: 0rem 0 0 0.75rem;
  padding: 0.05rem 0.5rem 0rem;
  border-radius: 0.25rem;
  font-size: 0.8rem;
}

@media (min-width: 768px) {
  .status-label-active,
  .status-label-paused,
  .status-label-cancelled {
    margin: 0rem 0 0 0.75rem;
    padding: 0.15rem 0.5rem 0rem;
    border-radius: 0.25rem;
    font-size: 1.1rem;
  }
}

.status-label-active {
  color: #2a6453;
  background-color: #d0eee8;
}

.status-label-paused {
  color: #45526c;
  background-color: #e6e6e6;
}

.status-label-cancelled {
  color: #da291c;
  background-color: rgba(218, 41, 28, 0.1);
}

.subscription-savings-label {
  margin-right: 0.5rem;
  padding: 0rem 0.5rem;
}

.account-subscription-order-total {
  border-top: 1px solid #7590cc;
}

.account-subscription-reactivation-button,
.account-subscription-resume-button {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.trademark-attribute {
  font-size: 1rem;
  margin: -0.2rem 0.15rem 0 0.15rem;
}

@media (min-width: 768px) {
  .trademark-attribute {
    font-size: 1rem;
    margin: -0.35rem 0.15rem 0 0.15rem;
  }
}
