div.gladlyHC-searchContainer {
    margin: 1rem 0;
}
input.gladlyHC-searchInput {
    padding: 1rem 2rem;
    color: #0032a0;
}
input.gladlyHC-searchInput::placeholder {
    color: #0032a0;
    opacity: 0.7;
}
div.gladlyHC-searchMenu {
    border-radius: 0;
}
div.gladlyHC-searchResultsContainer * {
    color: white;
}
h3.gladlyHC-searchResultsSection-heading {
    margin: 1em 0;
}
ul.gladlyHC-searchResultsSection-results {
    padding-left: 1em;
}

div.gladlyHC-faqContainer * {
    color: white;
}
h2.gladlyHC-faqHeading {
    display: none;
}
h2.gladlyHC-faqHeading {
    margin: 1rem 0;
}
h3.gladlyHC-faqSection-header {
    margin: 2rem 0 1rem;;
    font-size: 1.5rem;
}
@media (min-width: 768px) {
    div.gladlyHC-faqSection {
        margin-bottom: 5rem;
    }
    div.gladlyHC-faqSection:nth-child(2n) {
        padding-left: 5rem;
    }
}
li.gladlyHC-faqSection-listItem {
    margin: 0.5rem 0;
    font-family: 'SourceSansPro Light';
}

.gladlyHC-answerSection * {
    color: white;
}
.gladlyHC-answersIndex-link * {
    color: white;
}

/* Answer Detail */
.gladlyHC-answerDetail * {
    color: white;
}
h2.gladlyHC-answerDetail-name {
    margin: 2rem 0;
}
div.gladlyHC-answerDetail-content {
    font-family: 'SourceSansPro Light';
}

/* Answers Index */
div.gladlyHC-answersIndex-container * {
    color: white;
}
ul.gladlyHC-answersIndex-list {
    font-size: 1.5rem;
    text-align: left;
    list-style-type: none;
}
ul.gladlyHC-answersIndex-list li {
    margin: 2rem 0;
}
